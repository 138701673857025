import { useContext } from 'react';

import { WidgetContext } from './Provider';

const useWidgetContext = () => {
  const context = useContext(WidgetContext);

  if (!context) {
    throw new Error('[MA] Profile Card widget context is not defined');
  }

  return context;
};

export const useMember = () => useWidgetContext().member;
export const useUiState = () => useWidgetContext().ui;
export const useHandlers = () => useWidgetContext().handlers;
export const useComputedProps = () => useWidgetContext().computed;
