import React, { ComponentType } from 'react';

interface WithIsMobile {
  isMobile: boolean;
}

type ForDevice = <
  MobileProps extends WithIsMobile,
  DesktopProps extends WithIsMobile,
>(
  MobileComponent: ComponentType<React.PropsWithChildren<MobileProps>>,
  DesktopComponent: ComponentType<React.PropsWithChildren<DesktopProps>>,
) => ComponentType<React.PropsWithChildren<DesktopProps & MobileProps>>;

const forDevice: ForDevice = (MobileComponent, DesktopComponent) => (props) =>
  props.isMobile ? (
    <MobileComponent {...props} />
  ) : (
    <DesktopComponent {...props} />
  );

export default forDevice;
